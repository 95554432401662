export default {
  primary: {
    backgroundColor: 'primary',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: '1px',
    transition: 'all ease-in-out 0.5s',
    borderRadius: '5px',
    ':hover': {
      backgroundColor: 'secondary',
      borderColor: 'secondary',
    },
  },
  secondary: {
    backgroundColor: '#4f1313',
    color: 'white',
    fontWeight: 'bold',
    letterSpacing: '1px',
    border: 'solid 2px',
    borderColor: 'white',
    transition: 'all ease-in-out 0.5s',
    ':hover': {
      backgroundColor: 'black',
      color: 'white',
    },
  },
}
