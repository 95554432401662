export default {
  heading: 'Koulen, serif',
  body: 'Italiana',
  monospace: 'Menlo, monospace',
  display: 'Handlee, serif',
  googleFonts: [
    'Lato:100,200,300,400,500,600,700,800,900',
    'Italiana',
    'Koulen',
  ],
  // customFamilies: [''],
  // customUrls: [''],
}
