import buttons from './buttons';

export default {
  // ? ===   Layout  ====

  navigation: {
    background: 'white',
    backgroundColor: ['', '', '', 'white'],
    // height: '110px',
    '.image': {
      margin: '0rem',
      backgroundColor: 'white',
    },
    '.logo': {
      transform: 'unset',
      height: ['100%', '', '', 'unset'],
      position: 'static',
    },
    '.logoScrolled': {
      position: 'static',
      transform: 'unset',
    },
    '.navMenu': {
      borderBottom: 'none',
      padding: '1rem',
    },
    '.navItem': {
      borderLeft: '20px solid',
      borderColor: 'primary',
      borderRadius: '100px',
    },
    '.socialIconsContainer': {
      flexGrow: 'unset',
      margin: ['', '', '', 'auto 0rem 0.5rem'],
      alignItems: 'flex-start',
      justifyContent: 'flex-start',

      a: {
        borderRadius: '100px',
        padding: '0.25rem',
        backgroundColor: 'primary',
        margin: '0rem 0.5rem 0rem 0rem',
        height: '40px',
        width: '40px',
        border: 'solid 1px',
        borderColor: 'primary',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        svg: {
          margin: '0rem',
          path: {
            fill: 'white',
          },
        },
        ':hover': {
          backgroundColor: 'transparent',
          svg: {
            margin: '0rem',
            path: {
              fill: 'primary',
            },
          },
        },
      },
    },
    '.socialContainer': {
      borderBottom: 'solid 1px',
      borderColor: 'black',
      paddingBottom: '0.5rem',
    },
    '.contactDetailsContainer': {
      flexGrow: 'unset',
    },
  },

  footer: {
    borderTop: '2px solid',
    borderColor: 'secondary',
    backgroundColor: '#ffffff',
    padding: '1rem',
    color: 'text',
    maxWidth: ['', '', '', '80vw'],
    margin: '0 0rem 0rem auto',
    '.multiButtonContainer': {
      flexDirection: ['', '', '', 'column'],
      a: {
        color: 'primary',
        fontWeight: 'bold',
      },
    },
    '.aboutText': {
      color: 'black',
    },
    '.poweredByText': {
      justifyContent: 'flex-start',
    },
    '.copyright': {
      marginTop: '1rem',
    },
    '.sectionHeading': {
      color: 'secondary',
      textTransform: 'uppercase',
      fontSize: '1.5rem',
    },
    '.logo': {
      borderBottom: '1px solid',
      borderColor: 'secondary',
      paddingBottom: '1rem',
      marginBottom: '1rem',
      // filter: 'brightness(1) invert(1)',
    },
    '.quote': {
      fontFamily: 'display',
      color: 'secondary',
      fontWeight: 'bold',
    },
    '.gonationLogo': {
      filter: 'unset !important',
    },
    '.socialContainer svg path': {
      fill: 'black',
    },

    '.ctaButton': {
      variant: 'buttons.primary',
      marginTop: '1rem',
    },
  },

  ctaWidget: {},

  // ? ===========================
  // ? =====   reuseables   ======
  // ? ===========================

  title: {
    fontFamily: 'heading',
    fontSize: ['2rem', '2.5rem', '3rem', '3.5rem'],
    textTransform: 'uppercase',
    fontWeight: 'normal',
    order: '2',
  },
  subtitle: {
    fontSize: ['1.1rem', '1.25rem', '1.75rem', '2rem'],
    fontFamily: 'body',
    color: 'primary',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    order: '1',
    backgroundColor: '#4e1212',
    padding: '1rem',
    fontWeight: 'bold',
  },
  text: {
    fontSize: ['1rem'],
    color: 'text',
    opacity: '0.8',
    order: '3',
    fontWeight: 'bold',
    p: {
      fontSize: ['1.1rem'],
      color: 'text',
      lineHeight: '1.5',
      fontWeight: 'bold',
    },
  },

  sideBySide1: {
    '.content': {
      alignItems: 'center',
      textAlign: 'center',
      padding: ['2rem 1rem', '', '3rem', '4rem', ' 5rem'],
      border: 'solid 3px black',
    },
    '.title': {
      variant: 'customVariants.title',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
    },
    '.text': {
      variant: 'customVariants.text',
    },
    a: {
      order: '4',
    },
    '.ctaButton': {
      variant: 'buttons.primary',
    },
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.content': {
      backgroundColor: '#9c2c2c',
      alignItems: 'center',
      textAlign: 'center',
      padding: ['2rem 1rem', '', '3rem', '4rem', ' 5rem'],
      order: ['', '', '2'],
      '.title': {
        variant: 'customVariants.title',
        color: 'white',
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
      },
      '.text': {
        variant: 'customVariants.text',
        color: 'white',
        p: {
          color: 'white',
        },
      },
      '.ctaButton': {
        variant: 'buttons.secondary',
      },
    },
    '.lazyload-wrapper': {},
  },

  // ? ===   Homepage  ====

  homepageHero: {
    height: ['100vh', '', '', '80vh'],
    position: 'relative',
    '.hero_content_container': {
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
      top: '42%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      padding: '1rem',
      maxWidth: '767px',
      width: '90%',
      '.title': {
        variant: 'customVariants.title',
        color: 'white',
      },

      '.subtitle': {
        variant: 'customVariants.subtitle',
        backgroundColor: '#3800008c',
      },
      a: {
        order: '4',
      },
      '.ctaButton': {
        variant: 'buttons.primary',
      },
    },

    '.slick-slider': {
      height: ['100vh', '', '', '80vh'],
      position: 'relative',
      '.slick-slide > div': {
        height: ['100vh', '', '', '80vh'],
      },

      '.slick-slide img': {
        height: ['100vh', '', '', '80vh'],
        filter: 'brightness(0.5)',
      },
      '.slick-arrow': {
        bottom: '0rem',
        top: 'unset',
        left: 'unset',
        width: '45px',
        height: '45px',
        padding: '0.5rem',
        backgroundColor: 'primary',
        color: 'white',
        opacity: '0.7',
      },
      '.slick-next': {
        right: '1rem',
      },
      '.slick-prev': {
        right: '4.5rem',
      },
      '.slick-dots': {
        display: 'none !important',
      },
    },
  },
  homepageHeroShout: {
    // display: 'none',
    position: 'absolute',
    bottom: ['5rem', '', '2rem', '0.5rem'],
    left: ['0rem', '', '2rem', '2rem'],
    '.shoutWidget': {
      backgroundColor: 'rgb(105 73 51 / 70%)',
      '.shoutImage': {
        position: 'relative',
        top: '-1rem',
        border: 'none',
        left: '-1rem',
      },
      '.title': {
        color: 'white',
      },
      '.text': {
        color: 'white',
        fontSize: '1rem',
      },

      '.date': {
        color: 'white',
      },
    },
  },

  homepageTagline: {
    background: 'unset',
    backgroundColor: 'dark',
    padding: '8rem 1rem',
    color: 'white',
    '.section': {
      maxWidth: 'unset',
    },
    '.title': {
      fontSize: ['2rem', '3rem', '4rem', '5rem'],
      textTransform: 'uppercase',
    },
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide2',
  },
  homepageGallery: {
    variant: 'customVariants.sideBySide1',
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2',
  },

  homepageContact: {
    variant: 'customVariants.sideBySide1',
  },

  homepageFindUs: {
    padding: ['15vh 1rem', '', '20vh 1rem'],
    '.section': {
      // width: '75%',
      textAlign: 'left',
      alignItems: 'flex-start',
      margin: '0rem 0rem 0rem 10%',
    },
    '.title': {
      variant: 'customVariants.title',
      width: '100%',
      border: 'none',
      textAlign: 'left',
      color: 'light',
      textTransform: 'capitalize',
      fontSize: ['1.5rem', '2rem', '3rem', '4rem'],
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'primary',
      padding: '0rem',
      marginBottom: '1rem',
      backgroundColor: 'none',
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
    },
    a: {
      order: '4',
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
      fontSize: '1.2rem',
    },
  },

  // ? =======  About page  ========

  aboutPageAboutSection: {
    '.title': {
      color: 'secondary',
    },

    '.text': {
      fontWeight: 'bold',
    },
  },

  // ? =======  Menu page  ========

  menu: {
    // background: '#ddd1bf',
    maxWidth: 'unset',
    color: 'black',
    '.dropdownContainer': {
      display: 'none',
    },
    // '.menu > .menuContainer > .menuSectionTitle': {
    //   display: 'none',
    // },

    '#menuTab': {
      fontWeight: 'bold',
    },
    // *== Cells ==

    '.menuCell': {
      display: 'flex',
      borderRadius: '0px',
    },
    '.cellImage': {
      borderRadius: '0px',
    },

    '.cellName': {
      color: 'white',
      textAlign: 'center',
      width: '100%',
      margin: '0rem',
      padding: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    // *==  All in ==

    '.allInContainerWrapper': {
      borderTop: ['solid 10px', '', 'solid 30px'],
      borderBottom: ['solid 10px', '', 'solid 30px'],
      borderColor: ['primary', '', 'primary'],
      // paddingLeft: ['1rem', '2rem'],
    },
    '.allInContainer': {
      maxWidth: '1200px',
      margin: '0 auto',
    },
    '.menuContainer': {
      paddingBottom: '0rem',
    },

    // *==  menu section ==

    '.menuSectionTitle': {
      textShadow: '2px 3px red',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem'],
    },
    '.menuSectionDescription': {
      maxWidth: '767px',
      margin: '2rem auto',
    },

    // *==  menu Item ==

    '.menuItemContentContainer': {
      margin: '0rem',
    },
    '.menuItemName': {
      fontFamily: 'Lato, sans-serif',
      fontWeight: '700',
      marginBottom: '0.25rem',
      textTransform: 'uppercase',
    },
    '.menuItemDescription': {
      fontFamily: 'Lato, sans-serif',
      fontStyle: 'italic',
      fontWeight: '300',
      textTransform: 'capitalize',
    },

    '.menuItemPrice': {
      color: 'black',
    },
  },

  // ? ====  Events and Offers  ===

  eventsSection: {
    '.heading': {
      display: 'none',
    },
    '.textContent .title': {
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '', '', '2.5rem'],
      color: 'secondary',
      textAlign: 'center',
      maxWidth: '1000px',
    },
    '.modal': {
      backgroundColor: 'background',
      width: '90%',
      // '.eventDate': { color: 'white' },
      // '.modalEventTime': { color: 'white' },
    },
    '.logo': {
      // filter: 'brightness(1) invert(1)',
    },
    '.eventItemImage, .modalImage': {
      objectFit: 'contain',
      objectPosition: ['top', '', 'center center'],
      maxHeight: 'none',
    },
    '.events-container': {
      justifyContent: ['', '', 'stretch'],
    },
  },

  offersSection: {
    '.recurring-header': {
      display: 'none',
    },
    '.textContent .title': {
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '', '', '2.5rem'],
      color: 'secondary',
      textAlign: 'center',
      maxWidth: '1000px',
    },
    '.modal': {
      backgroundColor: 'secondary',
      '.eventDate': { color: 'white' },
      '.modalEventTime': { color: 'white' },
      '.eventDays': { color: 'white' },
    },
  },

  // ? ====  Gallery Page  ===

  gallery: {
    '.albumImage': {
      borderTop: '1px solid white',
      borderBottom: '1px solid white',
      variant: 'buttons.primary',
    },
    '.albumTitle': {
      variant: 'buttons.primary',
      padding: '0.5rem',
    },
  },

  // ? ====  Contact Page  ===

  contactForm: {
    padding: '3rem 1rem',
    order: '4',
    backgroundColor: '#902e2e',
    '.title': {
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '', '', '2.5rem'],
      color: 'white',
    },
    '.subtitle': {
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '2rem',
      color: 'white',
    },
    form: {
      input: {
        borderColor: 'white',
        '::placeholder': { color: 'white' },
      },
      textarea: {
        borderColor: 'white',
        '::placeholder': { color: 'white' },
      },
    },
  },

  locationMap: {
    order: '3',
    '.title': {
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '', '', '2.5rem'],
      color: 'secondary',
    },
    '.contactDetails-container, .hoursContainer ': {
      opacity: '0.9',
    },
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {
    padding: ['2rem 0rem', '2rem 0rem', '2rem 0rem', '2rem 0rem'],
    margin: '0rem',
    backgroundColor: ['white'],
    '.boxesHeading': {
      display: 'none',
    },
  },

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {},
};
